import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversations: [],
};

const conversationsSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversations: (state, action) => {
      state.conversations = action.payload.data;
    },
  },
});

export const { setConversations } = conversationsSlice.actions;
export default conversationsSlice.reducer;
