import React, { useEffect, useState } from "react";
import useFetch from "../../../features/hooks/useFetch";
import { Box, Paper, Typography } from "@mui/material";
import ImageComp from "../../../components/globalComponents/ImageComp";
import theme from "../../../theme";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/globalComponents/Layout/Layout";
import HeroSection from "../../../components/globalComponents/HeroSection";
const QuranCourses = () => {
  const { fetchData } = useFetch();
  const navigate = useNavigate();
  const [quranCourses, setQuranCourses] = useState(null);
  const getQuranicCourses = async () => {
    await fetchData(
      //here instead of parent category, privide id to get
      "/api/course?parentCategory=75a1ce2e-2ee1-4bbc-838b-c91613f40f05",
      undefined,
      (res) => {
        //console.log(res, "resssss");
        setQuranCourses(res.data);
        //console.log(res.data);
      }
    );
  };
  useEffect(() => {
    getQuranicCourses();
  }, []);
  return (
    <div>
      <HeroSection
        title={`Explore Quran Courses`}
        description={`Add Quran learning into your daily routine with our comprehensive Quranic courses.`}
      />
      <Layout title={"Learn Quran | SMAC"}>
        <Box
          sx={{
            marginTop: "25px",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          {quranCourses?.map((c) => {
            return (
              <Paper
                sx={{
                  width: "100%",
                  maxWidth: "544px",
                  cursor: "pointer",
                  ":hover": {
                    boxShadow: "0px 0px 10px 0px rgba(105,105,105,0.5)",
                  },

                  "@media(max-width:375px)": {
                    maxWidth: "300px",
                  },
                }}
                key={c.courseId}
                onClick={() => {
                  navigate(`/course/${c.courseId}`);
                }}
              >
                <ImageComp
                  src={c.images[0]}
                  alt={c.title}
                  sx={{ height: "270px", width: "100%", objectFit: "cover" }}
                />
                <Box
                  sx={{
                    padding: "24px",
                    "@media (max-width:375px)": {
                      padding: "12px",
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        marginBottom: "24px",
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {c.title}
                      </Typography>
                      <Typography variant="h4Black">
                        {c.description.substring(0, 100)}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "20px" }}>
                        <Box
                          sx={{
                            backgroundColor: "#EDEDED",
                            width: "max-content",
                            display: "flex",
                            gap: "8px",
                            padding: "5px 8px",
                            borderRadius: "4px",
                          }}
                        >
                          <Typography variant="subHeaderBlack">
                            Course Type:
                          </Typography>
                          <Typography variant="subHeader">{c.mode}</Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: "#EDEDED",
                            width: "max-content",
                            display: "flex",
                            gap: "8px",
                            padding: "5px 8px",
                            borderRadius: "4px",
                          }}
                        >
                          <Typography variant="subHeaderBlack">
                            Course Duration:{" "}
                          </Typography>
                          <Typography variant="subHeader">
                            {c.courseDuration}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#EDEDED",
                          width: "max-content",
                          display: "flex",
                          gap: "8px",
                          padding: "5px 8px",
                          borderRadius: "4px",
                          "@media(max-width:375px)": {
                            gap: "0px",
                            padding: "5px 5px",
                          },
                        }}
                      >
                        <Typography variant="subHeaderBlack">
                          Class Days:{" "}
                        </Typography>
                        <Typography variant="subHeader">
                          {c.classDays?.map((d, i) => {
                            let str = i === c.classDays.length - 1 ? " " : ", ";
                            return d + str;
                          })}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "#EDEDED",
                          width: "max-content",
                          display: "flex",
                          gap: "8px",
                          padding: "5px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography variant="subHeaderBlack">
                          Class Duration:{" "}
                        </Typography>
                        <Typography variant="subHeader">
                          {c.classDuration}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h4Black"
                        sx={{ textTransform: "capitalize" }}
                      >
                        By:{" "}
                        {c.user?.firstName.substring(0, 10) +
                          " " +
                          c.user?.lastName.substring(0, 10)}
                      </Typography>
                      <Typography variant="h4Black">
                        {moment
                          .utc(c.createdAt)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </Typography>
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          padding: "5px 16px",
                          color: "white",
                          borderRadius: "4px",
                        }}
                      >
                        {c.courseFee}PKR
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            );
          })}
        </Box>
      </Layout>
    </div>
  );
};

export default QuranCourses;
