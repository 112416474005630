import { List, ListItem, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Element,
  animateScroll as scroll,
  scroller,
  Events,
} from "react-scroll";

import theme from "../../theme";

// const styles = {
//   fontFamily: "sans-serif",
//   textAlign: "center",
// };

const ScrollNavigation = () => {
  // Effect for registering and unregistering events
  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register("begin", (to, element) => {
      console.log("begin", to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register("end", (to, element) => {
      console.log("end", to, element);
    });

    // Updating scrollSpy when the component mounts.
    // scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);
  const [activeClass, setActiveClass] = useState(0);
  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // };

  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -97,
    });
  };
  const style = {
    lineHeight: "36px",
    color: "#868686",
  };
  let inActiveStyles = {
    width: "100%",
    display: "inline-block",
    cursor: "pointer",
    background: "#E6E6E6",
    color: "#2E2E2E",
    padding: "12px 25px",
    borderRadius: "8px",
    fontWeight: 600,
    fontSize: "clamp(1rem, 0.447vw + 0.713rem, 1.25rem)",
  };
  let activeStyles = {
    color: "#FFFFFF",
    background: theme.palette.primary.main,
  };

  const links = [
    { name: "Introduction", to: "elevenInsideContainer" },
    { name: "Information We Collect", to: "firstInsideContainer" },
    { name: "How We Use User Information", to: "secondInsideContainer" },
    { name: "Information Sharing and Disclosure", to: "thirdInsideContainer" },
    { name: "Data Security", to: "fourthInsideContainer" },
    { name: "Your Privacy Rights", to: "fifthInsideContainer" },
    { name: "Cookies & Tracking Technologies", to: "sixInsideContainer" },
    { name: "Third-Party Links", to: "sevenInsideContainer" },
    { name: "Children's Privacy", to: "eightInsideContainer" },
    { name: "Changes to This Policy", to: "nineInsideContainer" },
    { name: "Contact Us", to: "tenInsideContainer" },
  ];
  return (
    <div
      style={{
        display: "flex",
        gap: "32px",
        padding: "5rem 0rem",
        position: "relative",
      }}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: "362px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          textDecoration: "none",
          listStyle: "none",
          padding: 0,
          margin: 0,
          position: "sticky",
          left: 0,
          top: 97,
          height: "max-content",
        }}
      >
        {links.map((item, index) => {
          return (
            <ListItem
              key={index}
              onClick={(e) => {
                setActiveClass(index);
                scrollTo(item.to);
              }}
              sx={
                activeClass !== index
                  ? { ...inActiveStyles }
                  : { ...inActiveStyles, ...activeStyles }
              }
            >
              {item.name}
            </ListItem>
          );
        })}
      </List>
      <div style={{ width: "2px", background: "#CDCDCD" }}></div>
      <Element
        name="test7"
        className="element"
        id="containerElement"
        style={{
          position: "relative",
          // height: "80vh",
          overflowY: "scroll",
        }}
      >
        <Element name="elevenInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">Introduction</Typography>
          <br />
          <Typography variant="aboutTerms">
            At SMAC your personal information is crucial for us. This Privacy
            Policy shows how we collect, use, store, and protect your personal
            information through our platform, which allows users to connect
            professionally, sell services or products, and buy or sell courses.
            By using SMAC you should agree to the rules described in this
            policy.
          </Typography>
        </Element>
        <Element name="firstInsideContainer" style={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">
            Information We Collect
          </Typography>
          <Typography>
            We collect information from users to deliver better services,
            including:
          </Typography>
          <ul style={style}>
            <li>
              <strong>Personal Information:</strong>Your Name, email address,
              your contact details, and profile information when you sign up at
              SAMAC.
            </li>
            <li>
              <strong>Financial Information:</strong>Payment information, such
              as credit/debit card information or PayPal account or Bank
              account, to process transactions.
            </li>
            <li>
              <strong>Location Data:</strong>When allowed, we collect
              geo-location data. The purpose of collecting data is to offer
              local services and product listings.
            </li>
            <li>
              <strong>Course Data:</strong> Data related to the courses you
              purchase, sell, or complete, including course growth, performance,
              and response.{" "}
            </li>
          </ul>
        </Element>
        <Element name="secondInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">
            How We Use User Information
          </Typography>
          <Typography>We use the collected information to:</Typography>
          <Typography>
            <ul style={style}>
              <li>Help in account creation and manage logins.</li>
              <li>
                Process payments, manage transactions, and offer customer
                support.
              </li>
              <li>
                Offer suitable recommendations for services, products, and
                courses.
              </li>
              <li>Improve our platform over analytics and user response.</li>
              <li>
                Allow communication between users (e.g., messaging for service
                queries or discussions about the course).
              </li>
              <li>
                Ensure legal compliance, avoid fraud, and improve system
                security
              </li>
            </ul>
          </Typography>
        </Element>
        <Element name="thirdInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">
            Information Sharing and Disclosure
          </Typography>
          <br />
          <Typography variant="aboutTerms">
            We do not sell or rent your Private or Personal information to other
            companies. Under these conditions, your information may be shared.
          </Typography>
          <ul style={style}>
            <li>
              <strong>With Service Providers:</strong> To make business
              operations smooth, such as payment processing, data storage, and
              marketing support.
            </li>
            <li>
              <strong>For Legal Reasons:</strong>If required by law or to
              protect the company rights, property, or safety of our users, or
              others.
            </li>
            <li>
              <strong>With Other Users:</strong>If you are involved in
              transactions, the essential information (e.g., email, contact
              number, home address) may be shared to complete the sale or
              purchase of products/services.
            </li>
          </ul>
        </Element>
        <Element name="fourthInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">Data Security</Typography>
          <br />
          <Typography variant="aboutTerms">
            We implement industry-standard security actions to protect your
            personal information from illegal access, disclosure, alteration,
            and damage. Despite these measures, any method of transmission over
            the Internet or electronic storage is 100% secure.
          </Typography>
        </Element>
        <Element name="fifthInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">
            Your Privacy Rights
          </Typography>
          <br />
          <Typography variant="aboutTerms">
            You have the following rights about your personal information:
          </Typography>
          <ul style={style}>
            <li>
              <strong>Access & Update:</strong> You can access, review, and
              change your Private information at any time using your account.
            </li>
            <li>
              <strong>Delete Information:</strong>You can request the deletion
              of your personal information or data, however, certain legal
              obligations may stop us from fully removing your data.
            </li>
          </ul>
        </Element>
        <Element name="sixInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">
            Cookies & Tracking Technologies
          </Typography>
          <br />
          <Typography variant="aboutTerms">
            To assess trends, monitor user behavior on the platform, and enhance
            user experience, we employ cookies and related technologies. Through
            the settings of your browser, you may control cookie choices on our
            platform.
          </Typography>
        </Element>
        <Element name="sevenInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">Third-Party Links</Typography>
          <Typography variant="aboutTerms">
            <br />
            Links to third-party websites, services, or adverts may be present
            on our platform. We advise you to review the privacy policies of
            those third-party websites since we are not responsible for their
            content. Links to other websites, services, adverts, or companies
            may be found on our platform.
          </Typography>
        </Element>
        <Element name="eightInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">
            Children's Privacy
          </Typography>
          <br />
          <Typography variant="aboutTerms">
            SMAC is planned for individuals and also for those who are under the
            age of 18. We knowingly collect personal information from children.
            If you are under 18, you can use our services. But you cannot post
            any explicit content on our posting platform
          </Typography>
        </Element>
        <Element name="nineInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">
            Changes to This Policy
          </Typography>
          <br />
          <Typography variant="aboutTerms">
            We may update this Privacy Policy from time to time to reveal
            changes in our Policies or legal needs. You will be notified of any
            change in policy through our platform or via email.
          </Typography>
        </Element>
        <Element name="tenInsideContainer" sx={{ marginBottom: "50px" }}>
          <Typography variant="aboutTermsHeading">Contact Us</Typography>
          <br />
          <Typography variant="aboutTerms">
            If you have any question or issues about our Privacy Policy, please
            feel free to contact us at:
            <ul style={style}>
              <li>
                <strong>Email:</strong>
                <a href="mailto:support@smac.com">support@smac.com</a>
              </li>
              <li>
                {" "}
                <strong>Phone:</strong>
                <a href="tel:+923441932822">+92-3441932822</a>
              </li>
            </ul>
          </Typography>
        </Element>
      </Element>
    </div>
  );
};

export default ScrollNavigation;
