import React, { useEffect, useState } from "react";
import Messages from "./Messages";
import ChatBox from "./ChatBox";
import { Box } from "@mui/material";
import useFetch from "../../features/hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "./../../features/hooks/useInnerWidth";
import { setSelectedChat } from "../../features/slice/socketSlice";
import { setConversations } from "../../features/slice/Chat/conversationsSlice";

function Main() {
  const [mblFlag, setMblFlag] = useState(false);
  const { selectedChat, isNewChat } = useSelector(
    (state) => state.selectedChat
  );
  // Handler to call on window resize
  const dispatch = useDispatch();
  const { fetchData } = useFetch();

  const getAllChatConversations = async () => {
    await fetchData("/api/chat", undefined, (res) => {
      if (res) {
        dispatch(setConversations({ data: res?.data }));
      }
    });
  };
  const { width } = useWindowSize();

  useEffect(() => {
    getAllChatConversations();
    return () => {
      dispatch(setSelectedChat({ selectedChat: null }));
    };
  }, [isNewChat]); // Empty array ensures that effect is only run on mount
  useEffect(() => {
    if (width < 850) {
      setMblFlag(true);
    } else {
      setMblFlag(false);
    }
  }, [width]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          mt: "30px",
        }}
      >
        <Messages
          mblFlag={mblFlag}
          setMblFlag={setMblFlag}
          windowSize={width}
          selectedChat={selectedChat}
        />
        <ChatBox windowSize={width} mblFlag={mblFlag} setMblFlag={setMblFlag} />
      </Box>
    </>
  );
}

export default Main;
